// import logo from './logo.svg';
import './App.css';
import Narbar from '../src/components/Navbar';
import {Routes, Route} from 'react-router-dom';
import Home from '../src/pages/Home';
import AboutMe from '../src/pages/AboutMe';
import Portfolio from '../src/pages/Portfolio';
import Resources from '../src/pages/Resources';
import Footer from '../src/components/Footer'
import {Toaster} from 'react-hot-toast';

function App() {
  return (
    <>
      <Narbar />
        <Toaster position='bottom-right' toastOptions={{duration:10000}}/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/aboutMe' element={<AboutMe />}/>
          <Route path='/portfolio' element={<Portfolio />}/>
          <Route path='/resources' element={<Resources />}/>
        </Routes>
      {/* <Footer /> */}
    </>
  );
}

export default App;
