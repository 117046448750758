import React from 'react';
// import { Link } from 'react-router-dom';
import '../styles/Home.css'; 

function Home() {
  return (
    <div className='home-page'>

      

      <section className="hero">
        <div className="container">
          <main className="main-content">
            <section className="introduction">
                {/* <img src="../imgs/photo.jpg" alt="Portrait" /> */}
                <h1>Hello! I'm Xiaoyu Zhou</h1>
                <h2>A beginner but passionate programmer specializing in software development.</h2>
            </section>
        </main>
          {/* <h1>Hi</h1>
          <h2>This is Xiaoyu Zhou. Welcome to my website.</h2>
          <p>Find me on <a href="https://github.com/XiaoyuZhou000">Github</a> or 
            <a href="https://www.linkedin.com/in/xyz-xiaoyuzhou/">Linkedin</a>
          </p>
          <p>Find my <a href="./Xiaoyu's_Resume_2022.10.pdf">Resume</a></p> */}
        </div>
      </section>

      {/* <footer className="footer">
        <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">
          <img alt="Creative Commons License" style={{ borderWidth:0 }} src="https://i.creativecommons.org/l/by/4.0/88x31.png" />
        </a>
        XYZHOU.site @ 2022 is licensed under a 
        <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">
          Creative Commons Attribution 4.0 International License
        </a>.
      </footer> */}
    </div>
  );
}

export default Home;
