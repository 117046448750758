import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import '../styles/Navbar.css'

export default function Navbar() {
  const location = useLocation(); 
  const isActive = (path) => location.pathname.startsWith(path);

  return (
    <nav className="nav">
      <div className='nav-left'>
        <h1 className="logo"><Link to="/">XYZHOU.site</Link></h1>
      </div>
        <div className='nav-right'>
          <Link to="/home" className={isActive('/home') ? 'active' : ''}>Home</Link>
          <Link to="/aboutMe" className={isActive('/aboutMe') ? 'active' : ''}>About Me</Link>
          {/* <Link to="/portfolio" className={isActive('/portfolio') ? 'active' : ''}>Portfolio</Link> */}
          <Link to="/resources" className={isActive('/resources') ? 'active' : ''}>Resources</Link>
        </div>
    </nav>
  )
}
