import React from 'react'
import '../styles/Resources.css'

export default function Resources() {
  return (
    <div className='Resources-page'>
      <section className="about-me">
        <section className='hero'>
          <div className='container'>
              <h2>My Resume: </h2>
              <a href="./Xiaoyu_Zhou_Resume2024.08.pdf" target="_blank" rel="noopener noreferrer">View My Resume</a>
              <h2>My Github: </h2>
            <a href='https://github.com/XiaoyuZhou000'>https://github.com/XiaoyuZhou000</a>
              
              {/* <section className="contact-info">
                📧 <a href="mailto: mail@xyzhou.site">mail@xyzhou.site</a>
                  <p>📞 (206) 294-8490</p>
              </section> */}
          </div>
          <div className='container'>
            <h2>My LinkedIn</h2>
            <a href='https://www.linkedin.com/in/xyz-xiaoyuzhou/'>https://www.linkedin.com/in/xyz-xiaoyuzhou/</a>
            <h2>My Number and Email</h2>
            <p>📞 (206) 294-8490</p>
            <a href="mailto: mail@xyzhou.site">mail@xyzhou.site</a>
          </div>
        </section>
      </section>
    </div>
  )
}
