import React from 'react'
import '../styles/AboutMe.css'

export default function AboutMe() {
  return (
    <div>
      <section className="about-me">
        <section className='hero'>
          <div className='container'>
            <p>Hello! I'm Xiaoyu Zhou, A beginner but passionate programmer specializing in software development. I'm currently exploring opportunities where I can contribute and further develop my programming skills.</p>

            <p>With a Bachelor's degree in <a href='https://acms.washington.edu/'>Applied and Computational Math Sciences: Data Science and Statistics</a> from <a href='https://www.washington.edu/'>University of Washington</a> and internships at different positions, I've honed my skills in Software Development Engineering. My projects range from developing user-friendly websites to building robust software solutions that improve business processes. I am eager to bring my expertise in coding, problem-solving, and innovative thinking to your team.</p>
              
              {/* <section className="contact-info">
                📧 <a href="mailto: mail@xyzhou.site">mail@xyzhou.site</a>
                  <p>📞 (206) 294-8490</p>
              </section> */}
          </div>
        </section>
      </section>
    </div>
  )
}
